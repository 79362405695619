import axios from "axios";
import { BASE_URL } from "./config";
const API_URL = BASE_URL;
const api = axios.create({
  baseURL: API_URL,
});
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const getProject = async (projectId) => {
  try {
    const response = await api.get(`${API_URL}/projects/${projectId}`);
    console.log(`Full URL: ${API_URL}/projects/${projectId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch project:", error);
    throw error;
  }
};

export const getProjects = async (skip = 0, limit = 40) => {
  try {
    const response = await api.get(`${API_URL}/projects`, {
      params: { skip, limit },
    });
    console.log(`Full URL: ${API_URL}/projects?skip=${skip}&limit=${limit}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch projects:", error);
    throw error;
  }
};

export const getProjectsByUser = async (userId, skip = 0, limit = 40) => {
  try {
    const response = await api.get(`${API_URL}/users/${userId}/projects`, {
      params: { skip, limit },
    });
    console.log(`Full URL: ${API_URL}/users/${userId}/projects?skip=${skip}&limit=${limit}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch projects by user:", error);
    throw error;
  }
};

export const createProject = async (projectData) => {
  try {
    const response = await api.post(`${API_URL}/projects`, projectData);
    //show the full URL, and the projectData
    console.log(`Full URL: ${API_URL}/projects`);
    console.log(`Project Data: ${projectData}`);
    return response.data;
  } catch (error) {
    console.error("Failed to create project:", error);
    throw error;
  }
};

export const deleteProject = async (projectId) => {
  try {
    const response = await api.delete(`${API_URL}/projects/${projectId}`);
    console.log(`Full URL: ${API_URL}/projects/${projectId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to delete project:", error);
    throw error;
  }
};

export const updateProject = async (projectId, projectData) => {
  try {
    const response = await api.put(
      `${API_URL}/projects/${projectId}`,
      projectData
    );
    console.log(`Full URL: ${API_URL}/projects/${projectId}`);
    console.log(`Project Data: ${projectData}`);
    return response.data;
  } catch (error) {
    console.error("Failed to update project:", error);
    throw error;
  }
};

export const getSourcingsByProject = async (projectId) => {
  try {
    const response = await api.get(
      `${API_URL}/projects/${projectId}/sourcings`
    );
    console.log(`Full URL: ${API_URL}/projects/${projectId}/sourcings`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch sourcings by project:", error);
    throw error;
  }
}; export const generateAIKeywords = (
  projectId,
  arrayInput,
  countryInput,
  languageInput,
  searchEnginesInput
) => {
  console.log('Sending request with data:', {
    project_id: projectId,
    array_input: arrayInput,
    country_input: countryInput,
    language_input: languageInput,
    search_engines_input: searchEnginesInput
  });

  api.post(
    `${API_URL}/generate_ai_keywords_new`,
    {
      project_id: projectId,
      array_input: arrayInput,
      country_input: countryInput,
      language_input: languageInput,
      search_engines_input: searchEnginesInput
    },
    {
      timeout: 60000, // 10 minutes
      headers: {
        'Content-Type': 'application/json',
      }
    }
  ).then(response => {
    console.log("AI Keywords generation request sent successfully.");
  }).catch(error => {
    console.error("Failed to send AI keywords generation request:", error.response?.data?.detail || error.message);
  });
};
export const updateProjectStatus = async (projectId, status) => {
  try {
    const response = await api.patch(
      `${API_URL}/projects/${projectId}/status`,
      {
        status: status,
      }
    );
    console.log(
      `Project status updated successfully for project ID: ${projectId} with status: ${status}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update project status:", error);
    throw error;
  }
};

export const getLastSourcingByProject = async (projectId) => {
  try {
    const response = await api.get(
      `${API_URL}/projects/${projectId}/sourcings/last`
    );
    console.log(`Full URL: ${API_URL}/projects/${projectId}/sourcings/last`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch last sourcing by project:", error);
    throw error;
  }
};

export const getProjectProgress = async (projectId) => {
  try {
    const response = await api.get(`${API_URL}/projects/${projectId}/progress`);
    console.log(`Full URL: ${API_URL}/projects/${projectId}/progress`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch project progress:", error);
    throw error;
  }
};

export const getUsers = async () => {
  try {
    const response = await api.get(`${API_URL}/users`);
    console.log(`Full URL: ${API_URL}/users`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch users:", error);
    throw error;
  }
};

export const createUser = async (userData) => {
  try {
    const response = await api.post(`${API_URL}/users`, userData);
    console.log(`Full URL: ${API_URL}/users`);
    console.log(`User Data: ${JSON.stringify(userData)}`);
    return response.data;
  } catch (error) {
    console.error("Failed to create user:", error);
    throw error;
  }
};

export const updateUser = async (userId, userData) => {
  try {
    const response = await api.put(`${API_URL}/users/${userId}`, userData);
    console.log(`Full URL: ${API_URL}/users/${userId}`);
    console.log(`User Data: ${JSON.stringify(userData)}`);
    return response.data;
  } catch (error) {
    console.error("Failed to update user:", error);
    throw error;
  }
};

export const deleteUser = async (userId) => {
  try {
    const response = await api.delete(`${API_URL}/users/${userId}`);
    console.log(`Full URL: ${API_URL}/users/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to delete user:", error);
    throw error;
  }
};

export const getLogin = async (username, password) => {
  try {
    const authData = new URLSearchParams();
    authData.append("username", username);
    authData.append("password", password);

    const response = await api.post(`${API_URL}/login`, authData);
    return response.data;
  } catch (error) {
    console.error("Failed to login:", error);
    throw error;
  }
};

export const getMe = async () => {
  try {
    const response = await api.get(`${API_URL}/me`);
    console.log(`Full URL: ${API_URL}/me`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch user data:", error);
    throw error;
  }
};

export const updateKeyword = async (keywordId, keywordData) => {
  try {
    console.log(`Attempting to update keyword with ID: ${keywordId}`);
    console.log(`Keyword Data: ${JSON.stringify(keywordData)}`);
    const response = await api.put(`${API_URL}/keywords/${keywordId}`, keywordData);
    console.log(`Keyword updated successfully for keyword ID: ${keywordId}`);
    console.log(`Response received: ${JSON.stringify(response.data)}`);
    return response.data;
  } catch (error) {
    console.error(`Failed to update keyword with ID: ${keywordId}:`, error);
    throw error;
  }
};

export const deleteKeyword = async (keywordId) => {
  try {
    console.log(`Attempting to delete keyword with ID: ${keywordId}`);
    const response = await api.delete(`${API_URL}/keywords/${keywordId}`);
    console.log(`Keyword deleted successfully for keyword ID: ${keywordId}`);
    console.log(`Response received: ${JSON.stringify(response.data)}`);
    return response.data;
  } catch (error) {
    console.error(`Failed to delete keyword with ID: ${keywordId}:`, error);
    throw error;
  }
};

export const getKeywordsByProject = async (projectId) => {
  try {
    const response = await api.get(`${API_URL}/projects/${projectId}/keywords`);
    console.log(`Full URL: ${API_URL}/projects/${projectId}/keywords`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch keywords by project:", error);
    throw error;
  }
};

export const getProjectKeywordsStatus = async (projectId) => {
  try {
    const response = await api.get(`${API_URL}/projects/${projectId}/keywords_status`);
    console.log(`Full URL: ${API_URL}/projects/${projectId}/keywords_status`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch keywords status:", error);
    throw error;
  }
};
