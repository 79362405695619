import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom'; import { ProgressBar } from "react-progressbar-fancy";
import Sourcing from './Sourcing'; // Make sure this component is set up for routing
import './App-login.css';
import { SlEye, SlLogout, SlBriefcase, SlControlPause, SlControlPlay } from "react-icons/sl";
import { getProjects, getProjectsByUser, getMe } from './api'; // Import the getProjects function from the api.js file
import { TbUserExclamation } from "react-icons/tb";
import { HiCheck } from "react-icons/hi2";
import { Puff, BallTriangle, Bars, Circles, Grid, Hearts, Oval, Rings, SpinningCircles, TailSpin, ThreeDots } from 'react-loading-icons'

const AutomatedSourcingTool = () => {
  const navigate = useNavigate();

  const [ongoingProjects, setOngoingProjects] = useState([]);
  const [completedProjects, setCompletedProjects] = useState([]);
  const [projectsToRemove, setProjectsToRemove] = useState([]);
  const [loading, setLoading] = useState(false); // New state for loading
  const [page, setPage] = useState(1); // New state for pagination
  const [hasMoreProjects, setHasMoreProjects] = useState(true); // New state to track if more projects are available

  const handleRemoveProject = (projectId) => {
    if (window.confirm("Are you sure you want to archive this project?")) {
      setProjectsToRemove([...projectsToRemove, projectId]);
    }
  };

  const handleAddNewProject = () => {
    navigate('/sourcing');
  };

  const handleDisconnect = () => {
    navigate('/');
  };

  const fetchProjects = async (page = 1) => {
    setLoading(true); // Start loading
    try {
      const userDetails = await getMe();
      console.log('User details:', userDetails);
      let projects = [];
      if (userDetails.role === 'User') {
        projects = await getProjects(page); // Pass page to getProjects
      } else if (userDetails.role === 'Limited User') {
        projects = await getProjectsByUser(userDetails.id, page); // Pass page to getProjectsByUser
      }
      const transformedProjects = projects.map(project => ({
        id: project.id,
        status: project.status,
        charge_code: project.charge_code,
        date: project.start_date,
        client: project.client_name,
        description: project.category,
        progress: project.progress
      }));
      const ongoing = transformedProjects.filter(project => project.progress < 100);
      const completed = transformedProjects.filter(project => project.progress === 100);

      setOngoingProjects(prev => [...prev, ...ongoing]);
      setCompletedProjects(prev => [...prev, ...completed]);
      setHasMoreProjects(projects.length === 40); // Check if there are more projects
    } catch (error) {
      console.error("Failed to fetch and transform projects:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    fetchProjects(page); // Initial fetch on component mount
  }, [page]);

  useEffect(() => {
    if (projectsToRemove.length > 0) {
      const timer = setTimeout(() => {
        setCompletedProjects(completedProjects.filter(project => !projectsToRemove.includes(project.id)));
        setProjectsToRemove([]);
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [projectsToRemove]);

  return (
    <div className="App">
      <div className="header">
        <h1><span className="titleLetters">D</span>ragon<span className="titleLetters">S</span>couter</h1>
      </div>
      <div className="automated-sourcing-tool">
        <div className="projects-div">
          <div className="projects-container projects-table">
            <div className="add-projects">
              <button onClick={handleDisconnect} className="disconnect-button"><SlLogout /></button>
              <button className="add-project-button" onClick={handleAddNewProject}>ADD NEW PROJECT</button>
            </div>
            <div className="ongoing-projects">
              <h2>ONGOING PROJECTS</h2>
              <table>
                <tbody>
                  {ongoingProjects.map(project => (
                    <tr key={project.id} style={{ cursor: 'pointer' }} onClick={() => navigate(`/sourcing/${project.id}`, { state: { loadedProj: project.id } })}>
                      <td>{project.status === 'PAUSED' && <SlControlPause />}
                        {project.status === 'ONGOING' && <SlControlPlay />}
                        {project.status === 'ONGOING_CONFIRM' && <SlControlPlay />}
                        {project.status === 'COMPLETED' && <HiCheck />}
                        {project.status === 'CONFIRM' && <TbUserExclamation />}
                      </td>
                      <td>{project.charge_code}</td>
                      <td>{project.date}</td>
                      <td>{project.client}</td>
                      <td>{project.description}</td>
                      <td>
                        <p>{project.progress}%</p>
                        <div style={{ width: `${project.progress}%`, height: '4px', backgroundColor: 'red' }}></div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {loading && completedProjects.length === 0 && (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                  <ThreeDots />
                </div>
              )}
            </div>
            <div className="completed-projects">
              <h2>COMPLETED PROJECTS</h2>
              <table>
                <tbody>
                  {completedProjects.map(project => (
                    <tr key={project.id} style={{ cursor: 'pointer' }} onClick={() => navigate(`/sourcing/${project.id}`, { state: { loadedProj: project.id } })}>
                      <td>{project.status === 'COMPLETED' && <HiCheck />}</td>
                      <td>{project.charge_code}</td>
                      <td>{project.date}</td>
                      <td>{project.client}</td>
                      <td>{project.description}</td>
                      <td>
                        <p>{project.progress}%</p>
                        <div style={{ width: `${project.progress}%`, height: '4px', backgroundColor: 'red' }}></div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {loading && completedProjects.length > 0 && (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                  <ThreeDots />
                </div>
              )}
            </div>
            {hasMoreProjects && !loading && (
              <button onClick={() => setPage(prev => prev + 1)} className="add-project-button" style={{ fontWeight: 'bold' }}>SEE MORE</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Project = () => {
  return (
    <Routes>
      <Route path="/" element={<AutomatedSourcingTool />} />
      <Route path="/sourcing" element={<Sourcing />} />
      <Route path="/sourcing/:projectId" element={<Sourcing />} />
      {/* Define other routes here */}
    </Routes>
  );
};

export default Project;